// Classes
import { ChartDataSet, ChartPieDataSet } from '@/Classes/Charts/ChartDefinition'
import FieldsOptions      from '@/Classes/Records/FieldsOptions'
import { BuilderManager } from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Module4  }  from '@/Constants/Modules/Module4'

// Store
import Store from '@/Store/Global/Default'

function _parseDatesForCharts(options: Module4.M40.Defaults.ChartOptionFilters) {
	// Establecer los valores de las variables segun el periodo de tiempo.
	switch (options?.period) {
		case AppValues.PeriodsChoiceList.CURRENT_MONTH:
			return PrimitiveTools.Dates.getLatestCorrelativeDays('String', new Date().getDate())
				.map((x) => _parseRegexForDates(x.toString(), options))
				.reverse()
		case AppValues.PeriodsChoiceList.CURRENT_YEAR:
			return PrimitiveTools.Dates.getLatestCorrelativeMonths('String', new Date().getMonth() + 1)
				.map((x) => _parseRegexForDates(x.toString(), options))
				.reverse()
		case AppValues.PeriodsChoiceList.LAST_12_MONTHS:
			return PrimitiveTools.Dates.getLatestCorrelativeMonths('String', 12)
				.map((x) => _parseRegexForDates(x.toString(), options))
				.reverse()
		case AppValues.PeriodsChoiceList.LAST_15_DAYS:
			return PrimitiveTools.Dates.getLatestCorrelativeDays('String', 15)
				.map((x) => _parseRegexForDates(x.toString(), options))
				.reverse()
		case AppValues.PeriodsChoiceList.LAST_30_DAYS:
			return PrimitiveTools.Dates.getLatestCorrelativeDays('String', 30)
				.map((x) => _parseRegexForDates(x.toString(), options))
				.reverse()
	}
}

function _parseRegexForDates(date: string, options: Module4.M40.Defaults.ChartOptionFilters) {
	if (options.group === AppValues.PeriodsGroupList.IN_MONTHS) date = date.replace(/^[\d]+\-/, '')
	if (options.group === AppValues.PeriodsGroupList.IN_DAYS)   date = date.replace(/\-[\d]+$/, '')
	return date
}

export function getChartGeneratorData(n: number) {
	switch (n) {
		case 1: case 2:
		return (chartBasicRef: any, chartConfig: any, arr: Array<any>, options: Module4.M40.Defaults.ChartOptionFilters) => {
			// Definición de las variables segun el periodo de tiempo.
			const dates: Array<Date | string> = _parseDatesForCharts(options)
		
			// Generar un grupo de 'CheckLists' agrupados por día/mes.
			const { chartData } = chartConfig
			const datesAsObject = PrimitiveTools.Objects.createArrayPropertiesFromStack(dates || [])
		
			// Iterar y conciderar unicamente los CheckLists que estan en el periodo de tiempo correspondiente.
			for (const checklist of arr) {
				const { date } = checklist
				if (date) {
					const _date = _parseRegexForDates(date.split(' ')[0], options)
					const matches = dates.find((x: string) => _date === x)
					if (matches) datesAsObject[_date].push(checklist)
				}
			}
		
			// Arrays con los valores de los 'DataSets'.
			const okValues = []
			const noValues = []
		
			// Iterar entre todos los 'checklists' agrupados.
			for (const key in datesAsObject) {
				let okSum = 0, noSum = 0
				const checklists = datesAsObject[key]
		
				for (const c of checklists) {
					okSum += c.ok
					noSum += c.not
				}
		
				// Obtener los valores pero como porcentajes.
				if (options.asPercentage) {
					const total = okSum + noSum
					okValues.push(Math.round((okSum * 100) / total) || 0)
					noValues.push(Math.round((noSum * 100) / total) || 0)
				}
				else {
					okValues.push(okSum)
					noValues.push(noSum)
				}
			}
		
			// Creación de los 'DataSets'.
			const okDataSet = new ChartDataSet('OK', okValues)
			.setBorderColor('#0779E4')
			.setFill(false)
			// .setLineTension(0)
		
			const noDataSet = new ChartDataSet('NO', noValues)
			.setBorderColor('#F90716')
			.setFill(false)
			// .setLineTension(0)
		
			chartData
			.clearDataSets()
			.setLabels(<Array<string>> dates)
			.addDataSet(okDataSet)
			.addDataSet(noDataSet)
		
			// Necesario para que las modificaciones de las opciones tengan efecto.
			chartBasicRef.render()
		
			// El objeto interno de la dependencia 'Charts.js' debe ser actualizado siempre que la información se actualice.
			chartBasicRef.update()
		}

		case 3:
		return (chartBasicRef: any, chartConfig: any, arr: Array<any>, options: Module4.M40.Defaults.ChartOptionFilters) => {
			// Definición de las variables segun el periodo de tiempo.
			const dates: Array<Date | string> = _parseDatesForCharts(options)
		
			// Generar un grupo de 'CheckLists' agrupados por día/mes.
			const { chartData } = chartConfig

			// Arrays con los valores de los 'DataSets'.
			const okValues = [], noValues = []
			let okSum = 0, noSum = 0
			
			// Iterar y conciderar unicamente los CheckLists que estan en el periodo de tiempo correspondiente.
			for (const checklist of arr) {
				const _date = _parseRegexForDates(checklist?.date.split(' ')[0], options)
				const matches = dates.find((x: string) => _date === x)

				// Nos interesa los CheckLists que coinciden con las fechas seleccionadas.
				if (matches) {
					okSum += checklist.ok
					noSum += checklist.not
				}
			}
		
			const total = okSum + noSum
			okValues.push(Math.round((okSum * 100) / total))
			noValues.push(Math.round((noSum * 100) / total))
		
			// Creación del unico DataSet para el Grafico tipo Pie.
			const pieDataSet = new ChartPieDataSet([...okValues, ...noValues])
			.setColors(['#0779E4', '#F90716'])
		
			chartData
			.clearDataSets()
			.setLabels(['OK', 'NO'])
			.addDataSet(pieDataSet)
		
			// Necesario para que las modificaciones de las opciones tengan efecto.
			chartBasicRef.render()
			
			// El objeto interno de la dependencia 'Charts.js' debe ser actualizado siempre que la información se actualice.
			chartBasicRef.update()
		}

		case 4: case 5:
		return (chartBasicRef: any, chartConfig: any, arr: Array<any>, options: Module4.M40.Defaults.ChartOptionFilters) => {
			// Definición de las variables segun el periodo de tiempo.
			const dates: Array<string> = _parseDatesForCharts(options)
		
			// Generar un grupo de 'CheckLists' agrupados por día/mes.
			const { chartData } = chartConfig
			const datesAsObject = PrimitiveTools.Objects.createArrayPropertiesFromStack(dates || [])
		
			// Iterar y conciderar unicamente los CheckLists que estan en el periodo de tiempo correspondiente.
			for (const checklist of arr) {
				const { date } = checklist
				if (date) {
					const _date = _parseRegexForDates(date.split(' ')[0], options)
					const matches = dates.find((x: string) => _date === x)
					if (matches) datesAsObject[_date].push(checklist)
				}
			}
		
			// Arrays con los valores de los 'DataSets'.
			const okValues = []
			const noValues = []
		
			// Iterar entre todos los 'checklists' agrupados.
			for (const key in datesAsObject) {
				let complete = 0, incomplete = 0
				const checklists = datesAsObject[key]
		
				for (const c of checklists) {
					if (c.not > 0) { incomplete++ }
					else { complete++ }
				}
		
				// Obtener los valores pero como porcentajes.
				if (options.asPercentage) {
					const total = complete + incomplete
					okValues.push(Math.round((complete * 100) / total) || 0)
					noValues.push(Math.round((incomplete * 100) / total) || 0)
				}
				else {
					okValues.push(complete)
					noValues.push(incomplete)
				}
			}
		
			// Creación de los 'DataSets'.
			const okDataSet = new ChartDataSet('OK', okValues)
			.setBorderColor('#6ECB63')
			.setFill(false)
			
			const noDataSet = new ChartDataSet('NO', noValues)
			.setBorderColor('#F90716')
			.setFill(false)
		
			chartData
			.clearDataSets()
			.setLabels(dates)
			.addDataSet(okDataSet)
			.addDataSet(noDataSet)
		
			// Necesario para que las modificaciones de las opciones tengan efecto.
			chartBasicRef.render()
			
			// El objeto interno de la dependencia 'Charts.js' debe ser actualizado siempre que la información se actualice.
			chartBasicRef.update()
		}

		case 6:
		return (chartBasicRef: any, chartConfig: any, arr: Array<any>, options: Module4.M40.Defaults.ChartOptionFilters) => {
			// Definición de las variables segun el periodo de tiempo.
			const dates: Array<Date | string> = _parseDatesForCharts(options)
		
			// Generar un grupo de 'CheckLists' agrupados por día/mes.
			const { chartData } = chartConfig

			// Arrays con los valores de los 'DataSets'.
			const okValues = [], noValues = []
			let complete = 0, incomplete = 0
			
			// Iterar y conciderar unicamente los CheckLists que estan en el periodo de tiempo correspondiente.
			for (const checklist of arr) {
				const _date = _parseRegexForDates(checklist?.date.split(' ')[0], options)
				const matches = dates.find((x: string) => _date === x)

				// Nos interesa los CheckLists que coinciden con las fechas seleccionadas.
				if (matches) {
					if (checklist.not > 0) incomplete++
					else complete++
				}
			}
		
			const total = complete + incomplete
			okValues.push(Math.round((complete * 100) / total))
			noValues.push(Math.round((incomplete * 100) / total))
		
			// Creación del unico DataSet para el Grafico tipo Pie.
			const pieDataSet = new ChartPieDataSet([...okValues, ...noValues])
			.setColors(['#6ECB63', '#F90716'])
		
			chartData
			.clearDataSets()
			.setLabels(['OK', 'NO'])
			.addDataSet(pieDataSet)
		
			// Necesario para que las modificaciones de las opciones tengan efecto.
			chartBasicRef.render()
			
			// El objeto interno de la dependencia 'Charts.js' debe ser actualizado siempre que la información se actualice.
			chartBasicRef.update()
		}

		case 7:
		return (chartBasicRef: any, chartConfig: any, arr: Array<any>, options: Module4.M40.Defaults.ChartOptionFilters) => {
			// Definición de las variables segun el periodo de tiempo.
			const dates: Array<Date | string> = _parseDatesForCharts(options)
		
			// Generar un grupo de 'CheckLists' agrupados por día/mes.
			const { chartData } = chartConfig
			
			// Iterar y conciderar unicamente los CheckLists que estan en el periodo de tiempo correspondiente.
			const uniqueAttributes: { [key: string]: { ok: number, not: number } } = {}
			for (const checklist of arr) {
				const _date = _parseRegexForDates(checklist?.date.split(' ')[0], options)
				const matches = dates.find((x: string) => _date === x)

				// Nos interesa los CheckLists que coinciden con las fechas seleccionadas.
				if (matches) {
					for (const attribute of checklist.attributes) {
						// Comprobar si el atributo existe como propiedad.
						if (!(attribute.name in uniqueAttributes)) uniqueAttributes[attribute.name] = { ok: 0, not: 0 }
						
						// Organizar la data de cada atributo.
						uniqueAttributes[attribute.name][attribute.status ? 'ok' : 'not']++
					}
				}
			}
		
			// Agrupar en Arrays los diferentes datos de los CheckLists.
			const data: Array<[string, number, number]> = []
			Object.keys(uniqueAttributes).forEach((x) => {
				const attribute = uniqueAttributes[x]
				const total = attribute.ok + attribute.not
				data.push([x, Math.round((attribute.ok * 100) / total), Math.round((attribute.not * 100) / total)])
			})
		
			// Aplicar un orden al Array (para visualizar la data en order).
			const labels: Array<string> = [], OKs: Array<number> = [], NOTs: Array<number> = []
			data.sort((a, b) => b[2] - a[2])
			data.forEach((x) => { labels.push(x[0]); OKs.push(x[1]); NOTs.push(x[2]) })
		
			// Creación de los 'DataSets'.
			const okDataSet = new ChartDataSet('OK', OKs)
			.setBackgroundColor('#0779E4')
			.setHidden(true)
		
			const noDataSet = new ChartDataSet('NO', NOTs)
			.setBackgroundColor('#F90716')
		
			chartData
			.clearDataSets()
			.setLabels(labels)
			.addDataSet(okDataSet)
			.addDataSet(noDataSet)
		
			// Necesario para que las modificaciones de las opciones tengan efecto.
			chartBasicRef.render()
			
			// El objeto interno de la dependencia 'Charts.js' debe ser actualizado siempre que la información se actualice.
			chartBasicRef.update()
		}
	}
}

export function getChartGeneratorDataTable(n: number, stacked: boolean) {
	switch (n) {
		case 1:
		return (dataTableRef: any, arr: Array<any>, options: Module4.M40.Defaults.ChartOptionFilters) => {
			// Definición de las variables segun el periodo de tiempo.
			const dates: Array<string> = _parseDatesForCharts(options)
		
			// Esta Tabla debe contener como 'fields' las fechas.
			const uniqueOperators: any = {}
			for (const checklist of arr) {
				const _date = _parseRegexForDates(checklist?.date.split(' ')[0], options)
				const matches = dates.find((x: string) => _date === x)

				// Nos interesa los CheckLists que coinciden con las fechas seleccionadas.
				if (matches) {
					// Comprobar si el _idOperator existe como propiedad.
					if (!(checklist._idOperator in uniqueOperators)) {
						uniqueOperators[checklist._idOperator] = {}
						dates.forEach((x) => uniqueOperators[checklist._idOperator][x] = 0)
					}
					uniqueOperators[checklist._idOperator][matches]++
				}
			}

			// Formatear la data obtenida para que pueda ser procesada.
			const response: Array<any> = []
			Object.keys(uniqueOperators).forEach((x) => {
				const field = uniqueOperators[x]
				response.push({ _idOperator: x, ...field })
			})

			// Clase Constructora.
			const bm = new BuilderManager(response)
			const { fields, items } = bm.getElements()

			// Mostrar unicamemte los campos que queremos en la tabla.
			if (stacked) bm.showFields(fields, ['_idOperator'])

			// Aplicar opciones a los campos.
			bm.parseFieldsOptions(fields, new FieldsOptions()
				.add('_idOperator', 'Operador').isSortable().setAlign('center', null).showDetailsButton()
			)

			// Realizar una Ejecución para cada Field.
			bm.forEachElement(fields, (field: any) => {
				if (field.key !== '_idOperator') {
					field.align = { field: 'center', item: 'center' }
				}
			})

			// Realizar una Ejecución para cada Cell.
			bm.forEachCell(items, (key: string, item: any) => {
				if (key !== '_idOperator' && key !== '_showDetails') {
					// Valor Actual de la Celda.
					const value = item[key]

					// Aplicar un 'style' según el valor de la Celda.
					const style = value === 0 ? 'background-color: rgb(255, 233, 233);' : 'background-color: rgb(30, 175, 78);'

					// Modificar la estructura de la celda para visualizar el nuevo estilo.
					item[key] = { style, value }
				}
			})

			// Definir los formatos que serán aplicados al valor de la celda.
			bm.parseItemsFormatters(items, {
				_idOperator: (v: string) => {
					const isOperator = Store.getters.getStoredOperatorById(v)
					const isUser     = Store.getters.getStoredUserById(v)
					const user       = isOperator || isUser
					return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
				}
			})

			// Definir los campos a mostrar en el Detalle.
			bm.generateDetailsFromItemsValueExcepts(items, ['_idOperator'])

			// Establecer los 'fields' e 'items' en el componente.
			dataTableRef.setElements(fields, items)
		}
	}
}