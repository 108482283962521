// Constants
import { AppValues } from '@/Constants/Global/AppValues'

// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace Module4 {
	export namespace M40 {
		enum TempMenuBarKeyOptions {
			ACTIVES,
			ADDONS,
			CHECKLISTS,
			KPI_CHARTS,
			KPI_OPERATORS,
			SETTING_AUDIT_CODE
		}

		export namespace Collections {
			export const MenuBarOptions = (shortened: boolean) => [
				{
					key: TempMenuBarKeyOptions.ACTIVES,
					icon: 'truck-pickup', label: shortened ? 'Actual' : 'CheckList Actual'
				},
				{
					key: TempMenuBarKeyOptions.CHECKLISTS,
					icon: 'list', label: 'Historico'
				},
				{
					key: TempMenuBarKeyOptions.ADDONS,
					icon: 'clipboard-check', label: 'Formularios'
				},
				{
					categoryIcon: 'chart-pie', categoryLabel: 'KPI\'s', categoryOpened: false,
					items: [
						{
							key: TempMenuBarKeyOptions.KPI_CHARTS,
							icon: 'chart-pie', label: 'CheckLists'
						},
						{
							key: TempMenuBarKeyOptions.KPI_OPERATORS,
							icon: 'chart-pie', label: 'Operarios'
						}
					]
				},
				{
					categoryIcon: 'cogs', categoryLabel: shortened ? 'Config.' : 'Configuraciones', categoryOpened: false,
					items: [
						{
							key: TempMenuBarKeyOptions.SETTING_AUDIT_CODE,
							icon: 'cogs', label: shortened ? 'Cód. Auditoria' : 'Código Auditoria'
						}
					]
				}
			]
		}
	
		export namespace Defaults {
			export interface ChartOptionFilters {
				asPercentage?: boolean
				group?: AppValues.PeriodsGroupList
				period?: AppValues.PeriodsChoiceList
			}
		
			export const MenuBarKeyOptions = {
				...TempMenuBarKeyOptions
			}
		
			export enum TypeActiveNames {
				FACILITY = 'Facility'
			}
			
			export enum TypeActiveTranslationNames {
				FACILITY = 'Instalación'
			}
		}
	
		export namespace JoiSchemas {
			const JoiSchemaLabels = {
				_idAdminCompany: '_idAdminCompany',
				_idCompany:      '_idCompany',
				_idResponsible:  '_idResponsible',
				code:            'Código Interno',
				dateValidation:  'Fecha Vigencia'
			}

			export const UpsertSettingAuditCode = Joi.object({
				_idAdminCompany: Joi.string().hex().required().label(JoiSchemaLabels._idAdminCompany),
				_idCompany:      Joi.string().hex().required().label(JoiSchemaLabels._idCompany),
				_idResponsible:  Joi.string().hex().required().label(JoiSchemaLabels._idResponsible),
				code:            Joi.string().required().label(JoiSchemaLabels.code),
				dateValidation:  Joi.string().required().label(JoiSchemaLabels.dateValidation)
			})
		}
	
		export namespace Socket {
			export enum Events {
				CREATED = 'CheckListCreated'
			}
		}
	}

	export namespace M41 {
		enum TempMenuBarKeyAssociationsOptions {
			CODE,
			STORAGES,
			EVERYTHING
		}
		
		enum TempMenuBarKeyOptions {
			EVALUATIONS,
			STORAGES,
		}
		
		export namespace Collections {
			export const MenuBarAssociationsOptions = [
				{
					key: TempMenuBarKeyAssociationsOptions.CODE,
					icon: 'list', label: 'Código'
				},
				{
					key: TempMenuBarKeyAssociationsOptions.STORAGES,
					icon: 'list', label: 'Plantas'
				},
				{
					key: TempMenuBarKeyAssociationsOptions.EVERYTHING,
					icon: 'list', label: 'Todo'
				}
			]
	
			export const MenuBarOptions = [
				{
					key: TempMenuBarKeyOptions.EVALUATIONS,
					icon: 'list', label: 'Evaluaciones'
				},
				{
					key: TempMenuBarKeyOptions.STORAGES,
					icon: 'list', label: 'Plantas'
				}
			]
		}
	
		export namespace Components {
			export namespace SideMenu {
				export const Initialization = {
					items: [
						{ type: 'button', label: 'Formularios', componentName: 'Addons', icon: 'building', isActive: true, items: [
							{ name: 'Clients', label: 'Formularios Cliente', isActive: true },
							{ name: 'ByDefault', label: 'Formularios por Defecto', isActive: false },
							{ name: 'ByStorage', label: 'Asignación por Planta', isActive: false }
						] },
						{ type: 'button', label: 'Instalaciones', componentName: 'Facilities', icon: 'warehouse', isActive: false, items: [
							{ name: 'List', label: 'Listado de Instalaciones', isActive: false },
							{ name: 'ByStorage', label: 'Asignación por Planta', isActive: false }
						] }
					]
				}
			}
		}
	
		export namespace Defaults {
			export const MenuBarKeyAssociationsOptions = {
				...TempMenuBarKeyAssociationsOptions
			}
	
			export const MenuBarKeyOptions = {
				...TempMenuBarKeyOptions
			}
		}
	
		export namespace JoiSchemas {
			const JoiSchemaAddonsLabels = {
				_idCheckListAddons: 'Formulario de Evaluación',
				_idAdminCompany:    'Cliente',
				_idStorages:        'Ubicaciones Asociadas',
				attributes:         'Listado de Evaluaciones',
				code:               'Código Formulario',
				groupArticle:       'Grupo Articulo',
				isForClient:        '¿Exclusivo Cliente?',
				name:               'Descripción',
				typeEquipment:      'Tipo de Equipo'
			}
			
			const JoiSchemaInstallationsLabels = {
				_idInstallation: 'Formulario de Evaluación',
				_idResponsible:  'Responsable',
				_idStorages:     'Ubicaciones Asociadas',
				code:            'Código Instalación',
				name:            'Descripción',
				isValid:         'IsValid'
			}

			export const AddCheckListAddon = Joi.object({
				_idAdminCompany: Joi.string().hex().required().label(JoiSchemaAddonsLabels._idAdminCompany),
				_idStorages:     Joi.array().items(Joi.string().hex()).label(JoiSchemaAddonsLabels._idStorages),
				attributes:      Joi.array().items(Joi.string()).label(JoiSchemaAddonsLabels.attributes),
				code:            Joi.string().required().label(JoiSchemaAddonsLabels.code),
				name:            Joi.string().required().label(JoiSchemaAddonsLabels.name),
				groupArticle:    Joi.string().required().label(JoiSchemaAddonsLabels.groupArticle),
				isForClient:     Joi.boolean().required().label(JoiSchemaAddonsLabels.isForClient),
				typeEquipment:   Joi.string().required().label(JoiSchemaAddonsLabels.typeEquipment)
			})
	
			export const AddInstallation = Joi.object({
				_idResponsible: Joi.string().hex().required().label(JoiSchemaInstallationsLabels._idResponsible),
				_idStorages:    Joi.array().items(Joi.string().hex()).min(1).label(JoiSchemaInstallationsLabels._idStorages),
				code:           Joi.string().required().label(JoiSchemaInstallationsLabels.code),
				name:           Joi.string().required().label(JoiSchemaInstallationsLabels.name)
			})
			
			export const UpdateCheckListAddon = Joi.object({ 
				_idCheckListAddons: Joi.string().hex().required().label(JoiSchemaAddonsLabels._idCheckListAddons),
				_idStorages:        Joi.array().items(Joi.string().hex()).label(JoiSchemaAddonsLabels._idStorages),
				attributes:         Joi.array().items(Joi.string()).label(JoiSchemaAddonsLabels.attributes),
				code:               Joi.string().required().label(JoiSchemaAddonsLabels.code),
				name:               Joi.string().required().label(JoiSchemaAddonsLabels.name),
				groupArticle:       Joi.string().required().label(JoiSchemaAddonsLabels.groupArticle),
				isForClient:        Joi.boolean().required().label(JoiSchemaAddonsLabels.isForClient),
				typeEquipment:      Joi.string().required().label(JoiSchemaAddonsLabels.typeEquipment)
			})
	
			export const UpdateInstallation = Joi.object({
				_idInstallation: Joi.string().hex().required().label(JoiSchemaInstallationsLabels._idInstallation),
				_idResponsible:  Joi.string().hex().required().label(JoiSchemaInstallationsLabels._idResponsible),
				_idStorages:     Joi.array().items(Joi.string().hex()).min(1).label(JoiSchemaInstallationsLabels._idStorages),
				code:            Joi.string().required().label(JoiSchemaInstallationsLabels.code),
				name:            Joi.string().required().label(JoiSchemaInstallationsLabels.name),
				isValid:         Joi.boolean().required().label(JoiSchemaInstallationsLabels.isValid)
			})
		}
	
		export namespace Socket {
			export enum Events {
				CREATED = 'CheckListCreated'
			}
		}
	}
}